// export { default } from "../old-app/app";
// import Routes from "../old-app/router";
// import Routes  from "../old-app/router";


import OldProductDetails from "~/old-app/views/old/old-product-details";
import NotFound from "./404";
import { NOT_FOUND_CODE } from "~/old-app/constants";



export async function loader() {
    throw new Response(null, {
        status: NOT_FOUND_CODE,
        statusText: "Not Found",
    });
}

export function ErrorBoundary() {
    return <NotFound />
}

export default OldProductDetails;

// export default Routes;